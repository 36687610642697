<template>
  <div class="template-1">
    <page-header class="page-header container">
      <h1>State Goal Planner</h1>
    </page-header>
    <security-banner v-if="!hasAccess" :i18n="translations.components"></security-banner>
    <div v-if="hasAccess">
      <camp-selector class="mb-5" :showCamp="false" :tooltip="translations.tcCurrentHome" :i18n="translations.components"
        :onlyUSA="true"></camp-selector>
      <page-body class="mx-lg">
        <dashboard></dashboard>
      </page-body>
      <page-footer></page-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import constantData from '@/json/data.json'
import Dashboard from '@/views/officers/state/goal-planner/Dashboard.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { stateBreadcrumbMixin } from '@/mixins/breadcrumbMixinFactory'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'state-goal-planner',
  mixins: [translationMixin, stateBreadcrumbMixin],
  data() {
    return {
      translations: {}
    }
  },
  async created() {
    await this.getTranslations()
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus'
    }),
    async getTranslations() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.getViewTranslations(),
        this.getComponentTranslations('security-banner', 'camp-select')
      ]).then(results => {
        const componentTranslations = results[2]
        this.$set(this.translations, 'components', componentTranslations)
        this.setLoadingStatus(false)
      })
    }
  },
  computed: {
    ...mapGetters({
      isInMenu: 'menu/isInMenu',
      userLogin: 'user/userLogin',
    }),
    hasAccess() {
      let hq = constantData.super_roles.find((csr) => csr.name === 'Headquarters')
      return (
        this.isInMenu(this.$route.path) ||
        (!!this.userLogin.croles && this.userLogin.croles.filter((cf) => cf.rle_key === hq).length > 0)
      )
    }
  },
  components: {
    campSelector: CampSelect,
    Dashboard,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    securityBanner: SecurityBanner
  }
}
</script>
  
<style lang="scss">
@import '@/styles/settings.scss';

.cwidth {
  min-width: 252px !important;
}
</style>
  