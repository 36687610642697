<template lang="">
  <b-modal
    v-model="modalShow"
    size="lg"
    :title="modalTitle"
    @close="$emit('close-modal')"
    @change="$emit('close-modal')"
  >
    <div v-if="isModalLoading">
      <b-skeleton-table :rows="12" :columns="4" :table-props="{ striped: true }"></b-skeleton-table>
    </div>
    <div v-else>
      <b-table
        responsive
        class="custom-table custom-table--modal"
        striped
        hover
        v-for="(items, itmKey) in campData"
        :key="itmKey"
        :items="items"
        :fields="fields"
      >
        <template #cell(goal)="data">
          <label v-if="isSelectedStateLocked() || camp.IsCampLocked">{{ data.value }}</label>
          <div v-else>
            <b-input-group prepend="$" :class="{ opaque: !isDollarValue(data.item.goalShortCode) }">
              <GoalBaseInput
                class="text-right px-2"
                v-model="items[data.index].goal"
                :maximumDigitsAllowed="getMaximumDigitsAllowed(data.item.goalShortCode)"
              />
            </b-input-group>
            <div v-if="$v.campData[itmKey].$each[data.index].$invalid" class="error">
              A number is required.
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <template #modal-footer="{ok,cancel,hide}">
      <button
        v-if="!camp.IsCampLocked"
        type="button"
        @click="saveCampGoals"
        class="btn btn-primary d-flex flex-row justify-content-end"
      >
        Save
      </button>
      <button class="btn btn-cancel" @click="$emit('close-modal')">
        Cancel
      </button>
    </template>
  </b-modal>
</template>
<script>
/*eslint-disable*/
import { mapActions, mapGetters } from 'vuex'
import GoalBaseInput from '@/components/camp-goal-planner/GoalBaseInput.vue'
import { YearRange } from '@/services/utils/YearRange.js'
import { required, numeric } from 'vuelidate/lib/validators'
import { FormatNumber } from '@/services/utils/FormatNumber.js'
import StateDashboardService from '@/services/goals/StateDashboard.js'

export default {
  components: {
    GoalBaseInput
  },
  inject: ['isSelectedStateLocked', 'isDollarValue'],
  props: {
    isModalShow: {
      type: Boolean,
      required: true
    },
    camp: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modalInitFormValues: {},
      modalShow: this.isModalShow,
      isModalLoading: true,
      fields: [],
      campData: {}
    }
  },
  validations: {
    campData: {
      receipts: {
        $each: {
          goal: { numeric, required }
        }
      },
      placements: {
        $each: { goal: { numeric, required } }
      },
      pwts: {
        $each: { goal: { numeric, required } }
      },
      churchPresentation: {
        $each: { goal: { numeric, required } }
      },
      membership: {
        $each: { goal: { numeric, required } }
      }
    }
  },
  computed: {
    ...mapGetters({
      userStateKey: 'user/userStateKey',
      statePlanningFiscalYear: 'stateGoalPlanner/statePlanningFiscalYear'
    }),
    modalTitle() {
      return 'Camp Quick Goal Planning - ' + this.camp.CampName
    },
    formatNumber() {
      return number => FormatNumber(number)
    },
    hasFormValidationError() {
      return this.$v.campData.$invalid
    }
  },
  methods: {
    ...mapActions({
      getCampQuickGoalPlanningData: 'stateGoalPlanner/getCampQuickGoalPlanningData',
      saveCampQuickGoalPlanningData: 'stateGoalPlanner/saveCampQuickGoalPlanningData'
    }),
    async saveCampGoals() {
      if (this.hasFormValidationError) {
        return
      }
      const payload = {
        CampOrgKey: this.camp.CampOrgKey,
        ChurchMinistryGoal:
          this.modalInitFormValues.ChurchMinistryGoal === this.campData.receipts[0].goal
            ? null
            : this.campData.receipts[0].goal,
        GideonCardGoal:
          this.modalInitFormValues.GideonCardGoal === this.campData.receipts[1].goal
            ? null
            : this.campData.receipts[1].goal,
        FaithFundGoal:
          this.modalInitFormValues.FaithFundGoal === this.campData.receipts[2].goal
            ? null
            : this.campData.receipts[2].goal,
        BarnabasFundGoal:
          this.modalInitFormValues.BarnabasFundGoal === this.campData.receipts[3].goal
            ? null
            : this.campData.receipts[3].goal,
        AuxilliaryScriptureGoal:
          this.modalInitFormValues.AuxilliaryScriptureGoal === this.campData.receipts[4].goal
            ? null
            : this.campData.receipts[4].goal,
        CampDesignatedGoal:
          this.modalInitFormValues.CampDesignatedGoal === this.campData.receipts[5].goal
            ? null
            : this.campData.receipts[5].goal,
        GideonPlacementGoal:
          this.modalInitFormValues.GideonPlacementGoal === this.campData.placements[0].goal
            ? null
            : this.campData.placements[0].goal,
        AuxillaryPlacementGoal:
          this.modalInitFormValues.AuxillaryPlacementGoal === this.campData.placements[1].goal
            ? null
            : this.campData.placements[1].goal,
        GideonPWTGoal:
          this.modalInitFormValues.GideonPWTGoal === this.campData.pwts[0].goal ? null : this.campData.pwts[0].goal,
        AuxiliaryPWTGoal:
          this.modalInitFormValues.AuxiliaryPWTGoal === this.campData.pwts[1].goal ? null : this.campData.pwts[1].goal,
        ChurchPresentationGoal:
          this.modalInitFormValues.ChurchPresentationGoal === this.campData.churchPresentation[0].goal
            ? null
            : this.campData.churchPresentation[0].goal,
        GideonMembershipGoal:
          this.modalInitFormValues.GideonMembershipGoal === this.campData.membership[0].goal
            ? null
            : this.campData.membership[0].goal,
        AuxiliaryMembershipGoal:
          this.modalInitFormValues.AuxiliaryMembershipGoal === this.campData.membership[1].goal
            ? null
            : this.campData.membership[1].goal
      }
      const response = await this.saveCampQuickGoalPlanningData({ campQuickGoalPlanningData: payload })
      if (response === 'Success') {
        this.$emit('state-dashboard-data-changed')
        this.$emit('close-modal')
      }
    },
    getMaximumDigitsAllowed(shortCode) {
      return StateDashboardService.getMaximumDigitsAllowed(shortCode)
    },
    setFields() {
      this.fields = [
        {
          key: 'categoryName',
          label: 'Category Name'
        },
        {
          key: 'goal',
          label: 'Goal',
          thClass: 'goal-text-right',
          tdClass: 'goal-text-right-table-cell',
          formatter: (value, key, item) => {
            return this.isDollarValue(item.goalShortCode) ? `$${FormatNumber(value)}` : FormatNumber(value)
          }
        },
        {
          key: 'fY1Goal',
          label: `${YearRange(this.statePlanningFiscalYear - 1)} Goal`,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: (value, key, item) => {
            return this.isDollarValue(item.goalShortCode) ? `$${FormatNumber(value)}` : FormatNumber(value)
          }
        },
        {
          key: 'fY2Goal',
          label: `${YearRange(this.statePlanningFiscalYear - 2)} Actual`,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: (value, key, item) => {
            return this.isDollarValue(item.goalShortCode) ? `$${FormatNumber(value)}` : FormatNumber(value)
          }
        }
      ]
    }
  },

  async created() {
    this.setFields()
    this.isModalLoading = true
    const data = await this.getCampQuickGoalPlanningData({
      stateKey: this.userStateKey,
      campKey: this.camp.CampOrgKey
    })
    this.campData = data

    // setting initial modal data for later comparison
    this.modalInitFormValues = {
      ChurchMinistryGoal: data.receipts[0].goal,
      GideonCardGoal: data.receipts[1].goal,
      FaithFundGoal: data.receipts[2].goal,
      BarnabasFundGoal: data.receipts[3].goal,
      AuxilliaryScriptureGoal: data.receipts[4].goal,
      CampDesignatedGoal: data.receipts[5].goal,
      GideonPlacementGoal: data.placements[0].goal,
      AuxillaryPlacementGoal: data.placements[1].goal,
      GideonPWTGoal: data.pwts[0].goal,
      AuxiliaryPWTGoal: data.pwts[1].goal,
      ChurchPresentationGoal: data.churchPresentation[0].goal,
      GideonMembershipGoal: data.membership[0].goal,
      AuxiliaryMembershipGoal: data.membership[1].goal
    }

    this.isModalLoading = false
  }
}
</script>
<style lang="scss">
.opaque {
  .input-group-prepend {
    opacity: 0;
  }
}
.error {
  display: block;
  color: #df1515;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  margin-left: 0px;
  margin-top: 5px;
}
.goal-text-right {
  text-align: right !important;
  min-width: 90px;
}
.modal-dialog {
  .g-panel {
    margin-bottom: 30px;
  }
  .g-panel__rgt {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    .btn {
      &:first-child {
        margin-bottom: 10px;
        margin-right: 0;
      }
    }
  }

  .arrow-btn {
    min-height: 27px;
    min-width: 35px;
    background: #01a9cf;
    border: none;
    border-radius: 3px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      max-width: 17px;
    }
  }
  .btn-primary {
    border: 2px solid #003946;
    font-weight: normal;
    &:hover {
      border-color: #fbc301;
    }
  }
  .btn-cancel {
    border: 2px solid #003946;
    background: #fff;
    font-weight: normal;
    color: #003946;
    &:hover {
      border-color: #003946;
      background: #003946;
      color: #fff;
    }
  }
  .modal-footer {
    background: #e0e8ef;
    border-top: none;
    padding-right: 25px;
    .btn {
      margin-left: 15px;
      text-transform: capitalize;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
.modal-header {
  border-color: #e3e3e3;
}
.modal-title {
  color: #000;
  font-family: 'DIN 1451 W01 Engschrift', sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  line-height: 32px;
}
.custom-table {
  &.custom-table--modal {
    .table {
      tr {
        th {
          &:first-child {
            width: 200px !important;
          }
        }
        td {
          &:first-child {
            width: 200px;
          }
        }
      }
    }
  }
}
.goal-text-right-table-cell {
  display: flex;
  justify-content: flex-end;
  text-align: right !important;
  min-width: 100px;
  .input-group {
    margin: 0;
    width: auto;
    border-radius: 0;

    .input-group-text {
      background: #ececec;
      color: #003946;
      border-radius: 0;
    }
  }
}
@media (min-width: 300px) and (max-width: 1200px) {
  .modal-lg {
    max-width: 95%;
  }
}
</style>
