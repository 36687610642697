<template lang="">
  <section>
    <div class="planner-header">
      <h4>{{ selectedCategory.categoryName }}</h4>
      <div class="planner-header__rgt">
        <label>View Details</label>
        <multiselect
          v-model="displayColumns"
          :options="toggleColumnOptions"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          label="label"
          track-by="id"
          :preselect-first="true"
          @select="showColumns"
          @remove="hideColumns"
        >
        </multiselect>
        <b-input-group class="rgt__searchbar">
          <template #append>
            <b-input-group-text
              ><img src="@/assets/svgs/goal-planner/search_ic.svg" alt="search" />
            </b-input-group-text>
          </template>
          <b-form-input v-model="filterCriteria" placeholder="Search"></b-form-input>
        </b-input-group>
      </div>
    </div>
    <b-table
      class="custom-table custom-table--thead-width"
      ref="table"
      :fields="visibleFields"
      :items="camps"
      :filter="filterCriteria"
      :sort-by.sync="sortBy"
      :per-page="rowsPerPage"
      :current-page="currentPage"
      @filtered="filterRecords"
      :filter-function="filterTable"
      show-empty
      striped
      hover
    >
      <template #head(Goal)="data"> {{ planFiscalYearName }} Goal </template>
      <template #head(GoalPast1)="data">
        <span v-b-tooltip.hover="{ customClass: 'custom-tooltip' }" title="Last planned fiscal year goal."
          ><img src="@/assets/svgs/goal-planner/info_white_ic.svg" alt="info"
        /></span>
        {{ cyMinus1Name }} Goal
      </template>
      <template #head(ActualPast1)="data">
        <span
          v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
          title="Achievement for most recent completed fiscal year."
          ><img src="@/assets/svgs/goal-planner/info_white_ic.svg" alt="info"
        /></span>
        {{ cyMinus1Name }} Actual
      </template>
      <template #head(ActualPast2)="data">
        <span v-b-tooltip.hover="{ customClass: 'custom-tooltip' }" title="Acheivement for indicated fiscal year."
          ><img src="@/assets/svgs/goal-planner/info_white_ic.svg" alt="info"
        /></span>
        {{ cyMinus2Name }} Actual
      </template>
      <template #head(ActualPast3)="data">
        <span v-b-tooltip.hover="{ customClass: 'custom-tooltip' }" title="Acheivement for indicated fiscal year."
          ><img src="@/assets/svgs/goal-planner/info_white_ic.svg" alt="info"
        /></span>
        {{ cyMinus3Name }} Actual
      </template>
      <template #cell(CampName)="data">
        <span>
          <a
            href="javascript:void(0);"
            @click="navigateToCamp({ campKey: data.item.CampOrgKey, campName: data.item.CampName })"
            >{{ data.value }}</a
          ></span
        >
      </template>
      <template #cell(Goal)="data">
        <!-- When Camp is unlocked and has been updated -->
        <span>{{ data.value }}</span>
      </template>
      <template #cell(GoalAction)="data">
        <ul class="hz-listing">
          <!-- warningPopUpHandler pass this when the camp is not 100% complete -->
          <li v-if="!data.item.IsCampLocked && !isStateGoalReadOnlyMode" @click="handlePopUp(data)">
            <img
              v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
              title="Lock Camp"
              src="@/assets/svgs/goal-planner/lock_ic.svg"
              alt="lock"
            />
          </li>
          <li
            v-else-if="data.item.IsCampLocked && !isStateGoalReadOnlyMode"
            @click="lockUnlockCampGoals(data.item.CampOrgKey, false)"
          >
            <img
              v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
              title="Unlock Camp"
              src="@/assets/svgs/goal-planner/ic_unlock.svg"
              alt="unlock"
            />
          </li>

          <li v-if="!data.item.IsCampLocked && !isStateGoalReadOnlyMode">
            <a
              href="javascript:void(0);"
              @click="navigateToCamp({ campKey: data.item.CampOrgKey, campName: data.item.CampName })"
            >
              <img
                v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
                title="Edit Camp Goals"
                src="@/assets/svgs/goal-planner/edit_table_ic.svg"
                alt="edit"
              />
            </a>
          </li>
          <li v-else>
            <a
              href="javascript:void(0);"
              @click="navigateToCamp({ campKey: data.item.CampOrgKey, campName: data.item.CampName })"
            >
              <img
                v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
                title="View Camp Goals"
                src="@/assets/svgs/goal-planner/ic_view.svg"
                alt="view"
              />
            </a>
          </li>
        </ul>
      </template>
    </b-table>
    <div class="table-controls">
      <div class="table-controls__col-lft">
        <div class="col-lft__inline">
          <label>Rows per page:</label>
          <select v-model="rowsPerPage" class="form-control">
            <option v-bind:value="10">10</option>
            <option v-bind:value="25">25</option>
            <option v-bind:value="50">50</option>
            <option v-bind:value="100">100</option>
          </select>
        </div>
        <div class="col-lft__txt">{{ tableFooterText }}</div>
      </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="rowsPerPage"
        first-text="⏮"
        prev-text="⏪"
        next-text="⏩"
        last-text="⏭"
        pills
        size="md"
      ></b-pagination>
    </div>
  </section>
</template>
<script>
/*eslint-disable*/

import Multiselect from 'vue-multiselect'
import { FormatNumber } from '@/services/utils/FormatNumber.js'
import GoalBaseInput from '@/components/camp-goal-planner/GoalBaseInput.vue'
import { mapActions, mapGetters } from 'vuex'
import { required, numeric } from 'vuelidate/lib/validators'
import StateDashboardService from '@/services/goals/StateDashboard.js'
import { YearRange } from '@/services/utils/YearRange.js'

export default {
  name: 'state-planner-table',
  components: { Multiselect, GoalBaseInput },
  inject: ['isDollarValue', 'isSelectedStateLocked'],
  props: {
    camps: {
      type: Array,
      default: () => []
    },
    selectedCategory: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      displayColumns: [],
      rowsPerPage: 10,
      currentPage: 1,
      filterCriteria: null,
      filteredItems: this.camps,
      sortBy: 'CampName',
      fields: [
        {
          key: 'CampName',
          label: 'Camp Name',
          visible: true,
          sortable: true,
          alwaysDisplay: true,
          filterByFormatted: true
        },
        {
          key: 'Goal',
          thClass: 'text-right',
          tdClass: 'text-right',
          visible: true,
          sortable: true,
          alwaysDisplay: true,
          formatter: 'goalFormatter'
        },
        {
          key: 'GoalPast1',
          thClass: 'text-right',
          tdClass: 'text-right',
          visible: false,
          sortable: true,
          alwaysDisplay: false,
          formatter: 'formatCellValue'
        },
        {
          key: 'ActualPast1',
          thClass: 'text-right',
          tdClass: 'text-right',
          visible: false,
          sortable: true,
          alwaysDisplay: false,
          formatter: 'formatCellValue'
        },
        {
          key: 'ActualPast2',
          thClass: 'text-right',
          tdClass: 'text-right',
          visible: false,
          sortable: true,
          alwaysDisplay: false,
          formatter: 'formatCellValue'
        },
        {
          key: 'ActualPast3',
          thClass: 'text-right',
          tdClass: 'text-right',
          visible: false,
          sortable: true,
          alwaysDisplay: false,
          formatter: 'formatCellValue'
        },
        {
          key: 'GoalAction',
          label: ' ',
          tdClass: 'text-right',
          visible: true,
          alwaysDisplay: true,
          sortable: false
        }
      ]
    }
  },
  validations: {
    camps: {
      $each: {
        GoalCalculated: { numeric, required }
      }
    }
  },
  computed: {
    ...mapGetters({
      userStateKey: 'user/userStateKey',
      stateDashboardDetails: 'stateGoalPlanner/stateDashboardDetails',
      userId: 'user/userId',
      statePageTableFilters: 'stateGoalPlanner/getStatePageSearchFilters',
      previousRoute: 'stateGoalPlanner/getPreviousRoute',
      isGoBackToStateFlowActive: 'stateGoalPlanner/getGoBackBtnValue',
      isStateGoalPlanningWindowOpen: 'stateGoalPlanner/isStateGoalPlanningWindowOpen',
      userLanguageKey: 'user/userLanguageKey'
    }),
    isStateGoalReadOnlyMode() {
      return this.isStateLocked || !this.isStateGoalPlanningWindowOpen
    },
    isStateLocked() {
      return this.isSelectedStateLocked()
    },
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
    totalRows() {
      return this.filteredItems.length
    },
    tableFooterText() {
      let rowCount = this.filteredItems.length
      let startCount = (this.currentPage - 1) * this.rowsPerPage + 1
      let endCount = (this.currentPage - 1) * this.rowsPerPage + this.rowsPerPage
      return (
        (startCount < rowCount ? startCount : rowCount) +
        ' - ' +
        (endCount < rowCount ? endCount : rowCount) +
        ' of ' +
        rowCount
      )
    },

    fiscalYear() {
      return this.stateDashboardDetails.FiscalYear
    },
    planFiscalYearName() {
      return this.stateDashboardDetails.PlanFiscalYear
    },
    cyMinus1Name() {
      return YearRange(this.fiscalYear - 1)
    },
    cyMinus2Name() {
      return YearRange(this.fiscalYear - 2)
    },
    cyMinus3Name() {
      return YearRange(this.fiscalYear - 3)
    },
    toggleColumnOptions() {
      return [
        {
          id: 'GoalPast1',
          label: `${this.cyMinus1Name} Goal`
        },
        {
          id: 'ActualPast1',
          label: `${this.cyMinus1Name} Actual`
        },
        {
          id: 'ActualPast2',
          label: `${this.cyMinus2Name} Actual`
        },
        {
          id: 'ActualPast3',
          label: `${this.cyMinus3Name} Actual`
        }
      ]
    }
  },

  methods: {
    ...mapActions({
      setStateToCampDashboardRedirectionData: 'stateGoalPlanner/setStateToCampDashboardRedirectionData',
      saveOfficerToolbarCampsSelection: 'user/saveOfficerToolbarCampsSelection',
      stateLockUnlockCampGoals: 'stateGoalPlanner/lockUnlockCampGoals',
      saveCampGoalByCategory: 'stateGoalPlanner/saveCampGoalByCategory',
      updatePreviousRoute: 'stateGoalPlanner/updatePreviousRoute',
      saveStateFilters: 'stateGoalPlanner/saveStateFilters',
      setIsPageState: 'stateGoalPlanner/setIsPageState',
      setGoBackBtn: 'stateGoalPlanner/setGoBackBtn',
      campGoalsLockOrUnlock: 'campGoalPlanner/campGoalsLockOrUnlock'
    }),

    loadFilters() {
      const filters = this.statePageTableFilters || {}
      const { searchCriteria, preSelectedColumns } = filters
      this.filterCriteria = searchCriteria || ''
      this.displayColumns = preSelectedColumns || []
    },

    async saveCampGoalHandler(campKey, index, fiscalYear) {
      if (!this.$v.camps.$each[index].$invalid) {
        try {
          const response = await this.campGoalsLockOrUnlock({
            campKey: campKey,
            fiscalYear: fiscalYear,
            isUserWantsToLockGoals: true,
            langKey: this.userLanguageKey
          })
          if (response === 'Success') {
            this.$parent.calculateCampsTotal()
          }
        } catch (error) {
          console.error('Error saving camp goal:', error)
        }
      }
    },

    async warningPopUpHandler() {
      this.$swal({
        icon: 'warning',
        title: 'Unable to lock goals ?', // Simple title
        html: `<p>Prior to locking the camp goal, all goal planning must be completed.</p>`,
        showCancelButton: false,
        confirmButtonText: 'Close', // Button text
        width: '600px',
        confirmButtonColor: '#003946',
        customClass: {
          popup: 'warning-popup', // Unique class for this popup
          confirmButton: 'warning-confirm-button' // Unique class for the button
        }
      })
    },

    async lockUnlockCampGoals(campKey, userWantToLockCampGoals, index, fiscalYear) {
      const callLockUnlockCampsAPI = async () => {
        const response = await this.stateLockUnlockCampGoals({
          CampOrgKey: campKey,
          LockGoals: userWantToLockCampGoals
        })
        if (response === 'Success') {
          this.$emit('state-dashboard-data-changed')
        }
      }

      if (userWantToLockCampGoals) {
        this.$swal({
          icon: 'warning',
          text: 'Are you sure that you want to lock this camp at this time?  Any changes made will be saved as well.',
          showCancelButton: true,
          cancelButtonColor: '#d33'
        }).then(async result => {
          if (result.isConfirmed) {
            await this.saveCampGoalHandler(campKey, index, fiscalYear)
            callLockUnlockCampsAPI()
          }
        })
      } else {
        callLockUnlockCampsAPI()
      }
    },
    //For showing Warning Message if the camps is not complete 100%
    handlePopUp(data) {
      if (data.item.IsModified) {
        this.lockUnlockCampGoals(data.item.CampOrgKey, true, data.index, data.item.GoalYear)
      } else {
        this.warningPopUpHandler()
      }
    },

    preSelectedSearchFilters() {
      this.saveStateFilters({
        searchCriteria: this.filterCriteria,
        preSelectedColumns: this.displayColumns,
        preFilteredCamps: this.filteredItems
      })
    },

    showColumns(column) {
      let fieldToBeDisplayed = this.fields.find(field => field.key == column.id)
      fieldToBeDisplayed.visible = true
    },
    hideColumns(column) {
      let fieldToBeDisplayed = this.fields.find(field => field.key == column.id)
      fieldToBeDisplayed.visible = false
    },
    triggerFiltersAndSearch() {
      // This method triggers the search manually
      this.$refs.table.refresh()
    },
    navigateToCamp({ campKey, campName }) {
      this.saveOfficerToolbarCampsSelection(campKey)
      this.setStateToCampDashboardRedirectionData({
        campName,
        isRedirectedBefore: true
      })
      this.preSelectedSearchFilters()
      this.updatePreviousRoute(this.$route.fullPath)
      this.setIsPageState(false)
      this.setGoBackBtn(false)
      this.$router.push({ name: 'goal-planner-cm' })
    },
    resetState() {
      this.fields.filter(field => field.alwaysDisplay == false).map(x => (x.visible = false))
      this.displayColumns = []
      this.filterCriteria = null
      this.rowsPerPage = 10
      this.currentPage = 1
      this.sortBy = 'CampName'
      //this.preSelectedSearchFilters()
    },
    filterRecords(filteredItems) {
      this.filteredItems = filteredItems
    },
    filterTable(row, filter) {
      const lowerCasedFilter = filter.toLowerCase()
      const checkIfColumnVisible = fieldKey => {
        for (const field of this.visibleFields) {
          if (field.key === fieldKey && field.visible) {
            return true
          }
        }
        return false
      }
      const goalColumnValue = this.goalFormatter('', '', row)
        .replace('$', '')
        .replace(',', '')
      if (
        row.CampName.toLowerCase().includes(lowerCasedFilter) ||
        goalColumnValue.includes(lowerCasedFilter) ||
        (checkIfColumnVisible('GoalPast1') && row.GoalPast1.toString().includes(lowerCasedFilter)) ||
        (checkIfColumnVisible('ActualPast1') && row.ActualPast1.toString().includes(lowerCasedFilter)) ||
        (checkIfColumnVisible('ActualPast2') && row.ActualPast2.toString().includes(lowerCasedFilter)) ||
        (checkIfColumnVisible('ActualPast3') && row.ActualPast3.toString().includes(lowerCasedFilter))
      ) {
        return true
      } else {
        return false
      }
    },
    goalFormatter(value, key, item) {
      return this.isDollarValue() ? '$' + FormatNumber(item.GoalProposed) : FormatNumber(item.GoalProposed)
    },
    formatCellValue(value, key, item) {
      let formattedNumber = FormatNumber(value)
      return this.isDollarValue() ? '$' + formattedNumber : formattedNumber
    },
    getMaximumNoOfDigitsAllowed() {
      return StateDashboardService.getMaximumDigitsAllowed(this.selectedCategory.categoryShortCode)
    },
    openQuickGoalPlanningModal(camp) {
      this.$emit('triggered-quick-goal-planning-modal', camp)
    },
    handlePageReload() {
      this.setIsPageState(true)
      this.setGoBackBtn(false)
    }
  },
  mounted() {
    if (this.previousRoute !== null) {
      this.loadFilters()
      const columns = this.statePageTableFilters.preSelectedColumns
      // Trigger search and value filter if values exist
      if (this.filterCriteria || columns.length > 0) {
        this.triggerFiltersAndSearch()
        columns.forEach(preSelectedColumnEntry => {
          this.showColumns(preSelectedColumnEntry)
        })
      }
      //To reset the route
    }
    this.updatePreviousRoute(null)
    window.addEventListener('beforeunload', this.handlePageReload)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handlePageReload)
  },
  watch: {
    userStateKey: {
      async handler() {
        if (this.isGoBackToStateFlowActive === false) {
          this.resetState()
        }
      },
      deep: true
    },
    camps: {
      async handler(newVal) {
        this.filteredItems = newVal
      },
      deep: true
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.input-group {
  margin: 0;
  width: auto;
  border-radius: 0;
  display: flex;
  flex-wrap: nowrap;

  .input-group-text {
    background: #ececec;
    color: #003946;
    border-radius: 0;
  }
}

.error-msg {
  display: block;
  color: #df1515;
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  padding-right: 12px;
  margin-top: 5px;
}

.custom-table {
  &.custom-table--thead-width {
    th {
      width: 99px !important;
    }
  }

  th {
    background-color: #003b48;
    color: #fff;
  }

  td {
    border-top: none;
  }

  .custom-table__input {
    max-width: 150px;
    padding: 3px 10px;
    font-size: 15px;
    text-align: right;
    margin-right: 6px;
  }

  .hz-listing {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    li {
      list-style: none;
      margin-left: 10px;

      img {
        cursor: pointer;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .badge {
    position: relative;
    padding: 0.35rem 0.75rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    display: inline-block;
    margin-left: 10px;
    font-size: 13px;
    font-weight: normal;
  }

  .badge--success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .edit-circle {
    width: 24px;
    height: 24px;
    background: #000;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #fbc301;
    }
  }
}

.planner-header {
  margin: 30px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin-bottom: 0;

    span {
      font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
      font-size: 20px;
      font-weight: 700;
      color: #747474;
      text-transform: capitalize;
      display: inline-flex;
      margin-left: 15px;
    }
  }

  .planner-header__rgt {
    display: flex;
    align-items: center;

    label {
      display: inline-flex;
      margin: 0;
      white-space: nowrap;
    }

    .multiselect {
      max-width: 412px;
      margin-left: 10px;
      min-width: 250px;
      min-height: inherit;

      .multiselect__input {
        padding: 3px 0 0 5px;
        margin-bottom: 0;
      }

      .multiselect__tag-icon {
        width: 19px;
      }

      .multiselect__tag-icon:focus,
      .multiselect__tag-icon:hover {
        background: #003946;
      }

      .multiselect__tags {
        border-color: #003946;
        border-radius: 0;
        padding-top: 5px;
        min-height: 38px;
      }

      .multiselect__tag {
        padding: 4px 20px 4px 8px;
        background: #e3e3e3;
        color: #003946;
        margin-right: 6px;
        margin-bottom: 4px;
        font-size: 13px;
      }

      .multiselect__option.multiselect__option--selected.multiselect__option--highlight {
        background: #003946;

        &:after {
          background: #003946;
        }
      }

      .multiselect__option.multiselect__option--highlight {
        background: #00a6ed;

        &:after {
          background: #00a6ed;
        }
      }
    }

    .form-control {
      border-right: none;
      margin-left: 15px;
    }

    .rgt__searchbar {
      width: auto;
      min-width: 250px;

      .input-group-append {
        .input-group-text {
          background: transparent;
          padding: 0.25em 0.65em;
          border-left: none;
          border-radius: 0;
        }
      }
    }
  }

  .planner-header__lft {
    display: flex;

    a {
      &:first-child {
        margin-right: 13px;
      }
    }

    .btn {
      padding: 11px 13px;
      text-transform: capitalize;

      img {
        margin-right: 5px;
      }
    }
  }
}

.table-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .form-control {
    padding: 0.375rem 0.55rem;
  }

  .table-controls__col-lft {
    display: flex;
    width: auto;

    .col-lft__txt {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #797b7b;
    }

    .col-lft__inline {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;

      label {
        margin: 0 10px 0 0;
        color: #797b7b;
      }
    }

    .form-control {
      width: auto;
    }
  }
}

.table.b-table > thead > tr > [aria-sort='descending'],
.table.b-table > tfoot > tr > [aria-sort='descending'] {
  background-image: url('../../assets/svgs/goal-planner/sort-down.svg');
}

.table.b-table > thead > tr > [aria-sort='ascending'],
.table.b-table > tfoot > tr > [aria-sort='ascending'] {
  background-image: url('../../assets/svgs/goal-planner/sort-up.svg');
}

.table.b-table > thead > tr > [aria-sort='none'],
.table.b-table > tfoot > tr > [aria-sort='none'] {
  background-image: url('../../assets/svgs/goal-planner/ic_sorting.svg');
}
</style>
